:root {
  --white-colour: #ffffff;
  --lightest-grey-colour: #f3f3f5;
  --lighter-grey-colour: #d9d9dc;
  --light-grey-colour: #c0c0c0;
  --purple-colour: #9d62f3;
  --pink-colour: #fc00ff;
  --yellow-colour: #fbc630;
  --aqua-colour: #00dbde;
  --blue-colour: #7688ed;
}
