$margin: 32px;

%noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 900px;

  padding: 16px 32px;
}

.heading {
  text-align: center;
}

.innerContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: -$margin;
}

%art {
  margin: 0;
  padding: 0;
}

.atpArt {
  @extend %art;
  border-radius: 5px;
  width: 128px;
  height: 128px;
}

.macstoriesArt {
  @extend %art;
}

.link {
  @extend %noselect;
  margin: 0 24px;
  margin-top: $margin;
}

/* Custom styles for JavaScript Weekly logo */
.javascriptWeeklyLink {
  @extend .link;

  display: inline-block;
  padding: 12px;
  color: #333;
  background-color: #f7df1e;
  text-decoration: none !important;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif;
}