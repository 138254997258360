.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
}

.verticalSplit {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  max-width: 1600px;
}

.fullWidth {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
}

.section {
  flex: 1;
  width: 100%;
  padding: 16px 32px;
}

.sectionTitle {
  margin-bottom: 16px;
  font-weight: 800;
}

.sectionContent {
  padding-top: 16px;
  border-top: 1px solid var(--lighter-grey-colour);
}

.progressContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.7rem;
  background: var(--lightest-grey-colour);
  margin-bottom: 1.7rem;
  border-radius: 20px;
}

.progressTitle {
  margin-bottom: 1.7rem;
}

.progressWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.progressMessage {
  flex: 1;
  margin: 0;
  padding-right: 32px;
}

.progress {
  flex: 0;
}

:global .react-sweet-progress-symbol {
  font-size: 1.31951rem;
  font-weight: 700;
}

@media (max-width: 1080px) {
  .verticalSplit {
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
