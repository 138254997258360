$height: 510px;
$padding: 8px;

.container {
  position: relative;
  height: $height;
  margin-bottom: 1.7rem;
}

.editor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global .CodeMirror {
  height: $height + $padding;
  border-radius: 5px;
  padding-top: $padding;
}
